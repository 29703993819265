import React, { useState, useContext } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import axios from '../api/axios';
import AuthContext from '../context/AuthProvider';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CHANGE_PASSWORD_URL = SERVER_URL + 'api/change-password';

const ChangePasswordContainer = styled.div`
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { token } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(
        CHANGE_PASSWORD_URL,
        JSON.stringify({ newPassword }),
        {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.accessToken}`
          },
          withCredentials: true,
        }
      );
      setMessage('Password changed successfully');
      setNewPassword('');
      setConfirmPassword('');
      setError('');
    } catch (err) {
      setError('Failed to change password');
    }
  };

  return (
    <ChangePasswordContainer>
      <h2>Change Password</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Confirm New Password</Form.Label>
          <Form.Control
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Button type="submit">Change Password</Button>
      </Form>
      {message && <Alert variant="success" className="mt-3">{message}</Alert>}
      {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
    </ChangePasswordContainer>
  );
};

export default ChangePassword;