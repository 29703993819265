
const NoPage = () => {
  return (
    <div>
      <h1>404</h1>
      <p>Page does not exist</p>
      <br/><br/> 
    </div>
  );
};

export default NoPage;
