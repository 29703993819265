import React from 'react';

function SVGPlan(props) {
  const width = props.width || '100%';
  const height = props.height || '100%';

  const renderAppleLogo = (x, y, size = 10) => (
    <use
      href="#apple-logo"
      x={x}
      y={y}
      width={size}
      height={size}
      fill="#999"
    />
  );

  return (
    <svg width={width} height={height} version="1.1" viewBox="0 0 300 125" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="shadow" x="-10%" y="-10%" width="120%" height="120%">
          <feDropShadow dx="1" dy="1" stdDeviation="1" floodColor="#000" floodOpacity="0.2" />
        </filter>
        <linearGradient id="roomGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#f0f0f0" />
          <stop offset="100%" stopColor="#e0e0e0" />
        </linearGradient>
        <symbol id="apple-logo" viewBox="0 0 170 170">
          <path d="M150.37 130.25c-2.45 5.66-5.35 10.87-8.71 15.66-4.58 6.53-8.33 11.05-11.22 13.56-4.48 4.12-9.28 6.23-14.42 6.35-3.69 0-8.14-1.05-13.32-3.18-5.197-2.12-9.973-3.17-14.34-3.17-4.58 0-9.492 1.05-14.746 3.17-5.262 2.13-9.501 3.24-12.742 3.35-4.929 0.21-9.842-1.96-14.746-6.52-3.13-2.73-7.045-7.41-11.735-14.04-5.032-7.08-9.169-15.29-12.41-24.65-3.471-10.11-5.211-19.9-5.211-29.378 0-10.857 2.346-20.221 7.045-28.068 3.693-6.303 8.606-11.275 14.755-14.925s12.793-5.51 19.948-5.629c3.915 0 9.049 1.211 15.429 3.591 6.362 2.388 10.447 3.599 12.238 3.599 1.339 0 5.877-1.416 13.57-4.239 7.275-2.618 13.415-3.702 18.445-3.275 13.63 1.1 23.87 6.473 30.68 16.153-12.19 7.386-18.22 17.731-18.1 31.002 0.11 10.337 3.86 18.939 11.23 25.769 3.34 3.17 7.07 5.62 11.22 7.36-0.9 2.61-1.85 5.11-2.86 7.51zM119.11 7.24c0 8.102-2.96 15.667-8.86 22.669-7.12 8.324-15.732 13.134-25.071 12.375a25.222 25.222 0 0 1-0.188-3.07c0-7.778 3.386-16.102 9.399-22.908 3.002-3.446 6.82-6.311 11.45-8.597 4.62-2.252 8.99-3.497 13.1-3.71 0.12 1.083 0.17 2.166 0.17 3.24z"/>
        </symbol>
      </defs>
      
      <g transform="translate(0 -14.7)" filter="url(#shadow)">
        <g fill="url(#roomGradient)" stroke="#ccc" strokeWidth="1">
          {/* Room outlines */}
          <rect x="10" y="16.7" width="170" height="120" rx="10" ry="10">
            <animate attributeName="opacity" values="0.7;1;0.7" dur="4s" repeatCount="indefinite" />
          </rect>
          <rect x="180" y="60" width="120" height="20" rx="5" ry="5" />
          <rect x="180" y="80" width="60" height="57" rx="5" ry="5" />
          <rect x="240" y="80" width="60" height="57" rx="5" ry="5" />
          
          {/* Furniture */}
          <rect x="50" y="100" width="10" height="10" fill="#ddd" rx="2" ry="2" />
          {renderAppleLogo(20, 118, 9)}
          {renderAppleLogo(83, 118, 9)}
          <rect x="130" y="100" width="10" height="10" fill="#ddd" rx="2" ry="2" />
          <rect x="133" y="113" width="5" height="4" fill="#4CAF50" rx="1" ry="1" />
        </g>
        
        {/* Doors */}
        <g fill="#90A4AE" stroke="#78909C" strokeWidth="0.5">
          <rect x="110" y="134" width="20" height="3" rx="1" ry="1" />
          <rect x="177" y="55" width="3" height="20" rx="1" ry="1" />
          <rect x="220" y="80" width="15" height="3" rx="1" ry="1" />
          <rect x="270" y="80" width="15" height="3" rx="1" ry="1" />
        </g>
      </g>
      
      {/* Text labels */}
      <g fontFamily="Arial, sans-serif" fontSize="6" fill="#333">
        <text x="75" y="50">Research Raum</text>
        <text x="200" y="75">Küche rechts</text>
        <text x="260" y="75">Küche links</text>
      </g>
    </svg>
  );
}

export default SVGPlan;


