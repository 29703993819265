import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthProvider';// Adjust the import path as needed

export default function RegisterForm({ mode = 'register' }) {
  const { token } = useContext(AuthContext); // Retrieve token from AuthContext
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      if (mode === 'changePassword') {
        setLoading(true);
        setError('');
        try {
          if (!token?.accessToken) {
            setError('You are not authenticated. Please log in again.');
            return;
          }
          const response = await axios.get('/api/users', {
            headers: { Authorization: `Bearer ${token.accessToken}` }
          });
          setUsers(response.data);
        } catch (err) {
          console.error('Error fetching users:', err);
          setError('Failed to fetch users. Please try again.');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUsers();
  }, [mode, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      if (mode === 'register') {
        await axios.post('/api/register', { username, password });
        alert('User registered successfully');
      } else if (mode === 'changePassword') {
        if (!token?.accessToken) {
          setError('You are not authenticated. Please log in again.');
          return;
        }
        await axios.post(
          '/api/change-password',
          { newPassword, userId: selectedUserId },
          { headers: { Authorization: `Bearer ${token.accessToken}` } }
        );
        alert('Password changed successfully');
      }
    } catch (err) {
      console.error(
        `Error: ${mode === 'register' ? 'registering user' : 'changing password'}`,
        err
      );
      setError(err.response?.data?.error || 'An error occurred. Please try again.');
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <form onSubmit={handleSubmit}>
      <h2>{mode === 'register' ? 'Register New User' : 'Change Password'}</h2>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {mode === 'register' && (
        <>
          <div>
            <label htmlFor="username">Username:</label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password">Password:</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
        </>
      )}

      {mode === 'changePassword' && (
        <>
          <div>
            <label htmlFor="user-select">Select User:</label>
            <select
              id="user-select"
              value={selectedUserId}
              onChange={(e) => setSelectedUserId(e.target.value)}
              required
            >
              <option value="">Choose a user</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.username}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="new-password">New Password:</label>
            <input
              id="new-password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
        </>
      )}

      <button type="submit">
        {mode === 'register' ? 'Register' : 'Change Password'}
      </button>
    </form>
  );
}
